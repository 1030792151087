

export const OKTA_ENV = {
    'CLIENT_URL': "https://identity.trinet.com",
    'LOCAL_AUTH_URL': "https://app.trinethire.com/okta_identities/local_authentication",
    'OKTA_REGISTRATION_URL': "https://app.trinethire.com/okta_identities",
    'GET_OKTA_IDENTITY_URL': "https://app.trinethire.com/okta_identities/",
    'GET_OKTA_IDENTITY_BY_STATE_TOKEN_URL': "https://app.trinethire.com/okta_identities/state/STATE_TOKEN",
    'RECOVERY_CREDENTIALS_URL': "https://app.trinethire.com/okta_identities/OKTA_ID/recovery_credentials",
    'LOCAL_AUTH_BY_FORGOT': "https://app.trinethire.com/okta_identities/local_authentication_by_forgot",
    'STYLESHEETS_URL': "https://dg4guz6k8vg2i.cloudfront.net/assets/cloud_auth-c711d894af30b47ddfcae8ad32c8f4d1e8e7c9d9db7dce09ce4e21a0081924b7.css",
    'AUTHENTICATE_URL': "https://app.trinethire.com/okta_identities/authenticate",
    'AIRBRAKE_ENABLED': true,
    'AIRBRAKE_PROJECT_ID': "151505",
    'AIRBRAKE_PROJECT_KEY': "a84130b8e8ec014687875069ae33ccb6",
    'AIRBRAKE_ENVIRONMENT': "production"
};
