import CloudAuth from 'cloud_js_utils/cloud_auth/src';
import HttpClient from "cloud_js_utils/cloud_auth/src/Utils/HttpClient";
import {OKTA_ENV} from "../config/cloud_auth.js";

const httpClient = new HttpClient;

$(document).ready(function () {
    const cloudAuth = new CloudAuth({
        client: {
            url: OKTA_ENV['CLIENT_URL']
        },
        endpoints: {
            localAuth: (username, password, csrfToken) => {
                return httpClient.request({
                    url: OKTA_ENV['LOCAL_AUTH_URL'],
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json'
                    },
                    data: JSON.stringify({username, password, 'authenticity_token': csrfToken})
                })
            },
            oktaRegistration: (token, identity) => {
                return httpClient.request({
                    url: OKTA_ENV['OKTA_REGISTRATION_URL'],
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'X-Cloud-Identity-Token': token
                    },
                    data: JSON.stringify(identity)
                })
            },
            getOktaIdentity: (token, identifier) => {
                return httpClient.request({
                    url: OKTA_ENV['GET_OKTA_IDENTITY_URL'] + identifier,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'X-Cloud-Identity-Token': token
                    }
                })
            },
            getOktaIdentityByStateToken: (token) => {
                return httpClient.request({
                    url: OKTA_ENV['GET_OKTA_IDENTITY_BY_STATE_TOKEN_URL'].replace('STATE_TOKEN', token),
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json'
                    }
                })
            },
            createRecoveryCredentials: (oktaId, password, question, answer) => {
                return httpClient.request({
                    url: OKTA_ENV['RECOVERY_CREDENTIALS_URL'].replace('OKTA_ID', oktaId),
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    data: JSON.stringify({password, question, answer})
                });
            },
            localAuthByToken: token => {
                return httpClient.request({
                    url: OKTA_ENV['LOCAL_AUTH_BY_FORGOT'],
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json'
                    },
                    data: JSON.stringify({token})
                })
            },
            stylesheets: [
            OKTA_ENV['STYLESHEETS_URL']
            ]
        },
        airbrake: {
            enabled: OKTA_ENV['AIRBRAKE_ENABLED'],
            config: {
                projectId: OKTA_ENV['AIRBRAKE_PROJECT_ID'],
                projectKey: OKTA_ENV['AIRBRAKE_PROJECT_KEY'],
                environment: OKTA_ENV['AIRBRAKE_ENVIRONMENT']
            }
        }
    });

    const urlParams = new URLSearchParams(window.location.search);
    const oktaForgotPasswordToken = urlParams.get('token');
    const forgotPasswordToken = urlParams.get('forgot_token');
    if (oktaForgotPasswordToken){
        cloudAuth.forgotPassword(oktaForgotPasswordToken)
        .then(sessionToken => {
            window.location.href = OKTA_ENV['AUTHENTICATE_URL'] + '?session_token=' + sessionToken;
        }).catch(() => {});
    }

    if (forgotPasswordToken) {
        cloudAuth.forgotPasswordByTriNetToken(forgotPasswordToken)
        .then(sessionToken => {
            window.location.href = OKTA_ENV['AUTHENTICATE_URL'] + '?session_token=' + sessionToken;
        }).catch(() => {});
    }

    $("#login_submit").on("click", function (event) {
        event.preventDefault();

        cloudAuth.authenticate(
            $('#user_email').val(),
            $('#user_password').val(),
            $("input[name='authenticity_token']").val()
            )
        .then(sessionToken => {
            window.location.href = OKTA_ENV['AUTHENTICATE_URL'] + '?session_token=' + sessionToken;
        }).catch(() => {});
    });
});
